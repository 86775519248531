<script setup lang="ts">
const {
  id,
  fit = 'cover',

} = defineProps<{
  id: number
  fit?: 'cover'
  width?: number
  height?: number
}>()

const { data, status } = useLazyFetch(`/api/media/${id}`, {
  key: `media/${id}`,
})
</script>

<template>
  <div
    v-if="status === 'pending'"
    class="relative"
  >
    <USkeleton
      class="w-full h-full bg-gray-900 rounded"
    />
    <div class="absolute inset-0 flex items-center justify-center">
      <UIcon
        name="hugeicons:image-01"
        class="text-gray-100 size-12 animate-pulse"
      />
    </div>
  </div>
  <NuxtImg
    v-else
    :alt="data?.alt"
    :src="data?.src"
    :width
    :height
    format="avif"
    placeholder
    :fit
    :quality="95"
  />
</template>
